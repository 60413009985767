import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";
import Layout from "../components/layout";
import { Text } from "../components/typography";

function NotFoundPage(props) {
  return (
    <Layout {...props}>
      <SEO title="404: Not Found" />
      <Text variant="large_C">Not Found</Text>
      <Text variant="medium_C">
        You just hit a route that doesn&#39;t exist... the sadness.
      </Text>
    </Layout>
  );
}

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
